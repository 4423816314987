<template>
    <div>
      <b-container>
        <b-row>
          <b-col>
              <div id="my-upload" style="display: flex; justify-content: center;">
                <vue-upload-multiple-image
                    :key="idupload"
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    @data-change="dataChange"
                    :data-images="form.images"
                    :dragText="components.picOptions.dragText"
                    :browseText="components.picOptions.browseText"
                    :primaryText="components.picOptions.primaryText"
                    :markIsPrimaryText="components.picOptions.markIsPrimaryText"
                    :popupText="components.picOptions.popupText"
                    :dropText="components.picOptions.dropText"
                    :showPrimary="false"
                    :multiple="false"
                    ></vue-upload-multiple-image>
                </div>
                <div class="directlink" v-if="form.imageURIBanner!=''">
                    <a
                    title="Abrir imagem usada como banner"
                    :href="form.imageURIBanner"
                    target="_blank"
                    >Banner</a>/
                    <a
                    title="Abrir imagem original"
                    :href="form.imageURIOriginal"
                    target="_blank"
                    >Imagem original</a>
                </div>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Nome da Empresa:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="name"
                                  maxlength="100"
                                  v-model="form.name"
                                  placeholder="Digite o nome da empresa">
                      </b-form-input>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          Url de Empresa:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="url"
                                  maxlength="100"
                                  v-model="form.url"
                                  placeholder="Digite o e-mail">
                      </b-form-input>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-input-group-prepend is-text class="firstLabel">
                          CNPJ:
                      </b-input-group-prepend>
                      <b-form-input id="name"
                                  type="text"
                                  name="cnpj"
                                  maxlength="25"
                                  v-mask="'##.###.###/####-##'"
                                  v-model="form.cnpj"
                                  placeholder="Digite o CNPJ">
                      </b-form-input>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                <b-input-group size="sm">
                    <b-input-group-prepend is-text class="firstLabel">
                        Código de acesso:
                    </b-input-group-prepend>
                    <b-form-input id="name"
                                type="text"
                                name="code"
                                v-model="form.code"
                                placeholder="Digite o Código de acesso">
                    </b-form-input>
                </b-input-group>
              </b-row>
              <b-row class="mb-3">
                <b-input-group size="sm">
                  <b-input-group-prepend is-text class="firstLabel">
                    Quantidade de funcionário:
                  </b-input-group-prepend>
                  <b-form-input
                    id="qtde_func"
                    type="text"
                    name="qtde_func"
                    v-model="form.qtde_func"
                    placeholder="Quantidade de funcionário">
                  </b-form-input>
                </b-input-group>
              </b-row>
              <b-row class="mb-3">
                <div v-if="!isAdd" style="margin-right: 15px;" class="alert alert-primary">Deseja alterar o código de acesso? Digite o código de acesso no campo e salve.</div>
              </b-row>
              <b-row  class="mb-3" v-if="!isAdd">
                <b-button @click="openModalExclusiveList = true">Personalizar base de eventos exclusivos para este parceiro corporativo</b-button>
                <event-exclusive v-if="openModalExclusiveList" @close="openModalExclusiveList = false;" :corporate-id="id" />
              </b-row>
              <b-row class="mb-3">
                <b-input-group size="sm">
                  <b-input-group-prepend
                    is-text
                  >Mensagem do banner:</b-input-group-prepend>
                  <div
                    class="col-12 m-0 p-0 containerMessagePromo"
                  >
                    <textarea
                      class="bannerMessagePromo"
                      v-model="form.messagepromo"
                      maxlength="1000"
                      placeholder="Digite aqui (Opcional)"
                    ></textarea>
                  </div>
                </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-form-checkbox id="require_login"
                                      v-model="form.require_login"
                                      value="1"
                                      unchecked-value="0">
                      <span>Login obrigatório na listagem de eventos</span>
                      </b-form-checkbox>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                  <b-input-group size="sm">
                      <b-form-checkbox id="status"
                                      v-model="form.status"
                                      value="Ativo"
                                      unchecked-value="Inativo">
                      <span v-if="form.status == 'Ativo'">Ativo</span>
                      <span v-else>Inativo</span>
                      </b-form-checkbox>
                  </b-input-group>
              </b-row>
              <b-row class="mb-3">
                <b-button type="button" variant="success" size="sm" @click="save">
                  <v-wait for="inprocess">
                      <template slot="waiting">
                          Carregando...
                      </template>
                  </v-wait>
                  <v-wait for="inprocessSave">
                      <template slot="waiting">
                          Salvando...
                      </template>
                  </v-wait>
                  <span v-if="!processing">Salvar</span>
                </b-button>
              </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
</template>

<script>
import Vue from "vue";
import VueMask from 'v-mask';
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { func } from "@/functions";
import { corporateService } from '../../components/common/services/corporate';
import { dashboardService } from "../../components/common/services/dashboard";

Vue.use(VueMask);

export default {
  mixins: [func],
  props: ['id'],
  components: { VueUploadMultipleImage, EventExclusive: () => import('./event-exclusive.vue') },
  name: 'corporate-add',
  head: {
    title: function () {
      return { 
        inner: `TicketOffice | Admin`,
        separator: " | ",
        complement: `Usuário - ${this.typeOf}`,
      }
    },
  },
  data () {
    return {
        idupload: '',
        openModalExclusiveList: false,
        processing: false,
        loading: false,
        form: {
          imageURIBanner:'',
          imageURIOriginal: '',
          loaded: false,
          imgbase64: "",
          images: [],
          image: "",
          saveimage: false,
          code: '',
          changedcode: false,
          id: '',
          name: '',
          cnpj: '',
          url: '',
          messagepromo: '',
          status: 'Ativo',
          require_login: '1',
        },
        components: {
            picOptions: {
              dragText: "Arrastar imagem",
              browseText: "Selecione",
              primaryText: "Padrão",
              markIsPrimaryText: "Definir como padrão",
              popupText: "Esta imagem será exibida como padrão",
              dropText: "Solte aqui"
            },
        },
    }
  },
  created () {
    if (!this.isAdd) {
      this.get();
    }
  },
  computed: {
    mayIsee() {
      return this.mayI('corporate-add', 'corporate-viewer');
    },
    typeOf() {
      return this.isAdd ? "Adicionar" : "Alterar";
    },
    isAdd() {
      return this.id == '' || this.id == null || this.id == undefined;
    }
  },
  methods: {
    validate() {
      if (this.form.code === '' || this.form.code.length < 4) {
        this.toastError("Preencha o código corretamente. (Mínimo 4 caracteres)");
        return false;
      }
      if (!this.form.name) {
        this.toastError("Preencha o nome corretamente.");
        return false;
      }
      return true;
    },
    save() {
      if (this.processing) return;

      if (!this.validate()) return;

      this.form.changedcode = this.form.code != '';

      this.processing = true;

      this.$wait.start("inprocessSave");
      this.showWaitAboveAll();
      corporateService.save(this.getLoggedId(), this.isAdd ? '' : this.id, this.form.name, this.form.cnpj, this.form.url, this.form.code, this.form.changedcode, this.form.saveimage, this.form.image, this.form.messagepromo, this.form.qtde_func, this.form.status, this.form.require_login).then(
        response => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocessSave");

          if (this.validateJSON(response))
          {
             if (response.success) {
               this.toastSuccess("Salvo com sucesso");
               this.$router.push(`/corporate/list`);
             }
             else {
               this.toastError(response.msg);
             }
          }
        },
        error => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocessSave");
          this.toastError("Falha na execução.");
        }
      );      
    },
    get() {
      if (this.processing) return;

      this.processing = true;

      this.$wait.start("inprocess");
      this.showWaitAboveAll();
      corporateService.get(this.id).then(
        response => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");

          if (this.validateJSON(response))
          {
            this.form.loaded = true;
            this.form.name = response.name;
            this.form.cnpj = response.cnpj;
            this.form.url = response.url;
            this.form.code = response.code;
            this.form.qtde_func = response.qtde_func;
            this.form.messagepromo = response.messagepromo;
            this.form.imageURIBanner = response.imageURIBanner;
            this.form.imageURIOriginal = response.imageURIOriginal;
            this.form.status = response.status;
            this.form.require_login = response.require_login;
            console.log(response);
            
            dashboardService.getEmployees({ code: response.code }).then(response => {
              if (this.validateJSON(response)) {
                const a = document.getElementById('qtde_func')
                a.value = response.dados.total.toString()
              }
            })

            this.populateImage();
          }
        },
        error => {
          this.processing = false;
          this.hideWaitAboveAll();
          this.$wait.end("inprocess");
          this.toastError("Falha na execução.");
        }
      )
    },
    uploadImageSuccess(formData, index, fileList) {
      //console.log(fileList);
      this.form.saveimage = true;
      this.form.image = fileList[index].path;
      console.log("uploadImageSuccess");
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      this.form.saveimage = false;
      this.form.image = null;
      done();
      console.log("beforeRemove");
    },
    editImage(formData, index, fileList) {
      setTimeout(() => {

        this.form.saveimage = true;
        this.form.image = fileList[index].path;
  
        console.log("editImage");
      }, 100)
    },
    dataChange(data) {
      console.log("dataChange");
      console.log(data);
    },
    populateImage() {
      Vue.nextTick().then(response => {
        this.$wait.start("inprocess");
        this.showWaitAboveAll();
        corporateService.base64(this.id, "ori").then(
          response => {
            this.hideWaitAboveAll();
            this.$wait.end("inprocess");

            if (this.validateJSON(response)) {
              this.form.imgbase64 = response.code;
              this.imageobj();
            }
          },
          error => {
            this.hideWaitAboveAll();
            this.$wait.end("inprocess");
            this.toastError("Falha na execução.");
          }
        );
      });
    },
    imageobj() {
      Vue.nextTick().then(response => {
        let obj = {
          default: 1,
          highlight: 1,
          name: "default.jpg",
          path: this.form.imgbase64
        };
        this.form.images.push(obj);
        this.idupload++;
      });
    },
  }
}
</script>

<style>
.Password {
    max-width: 400px;
    margin: 0 0 !important;
}
.bannerMessagePromo {
  width: 100%;
  border: 1px solid rgba(0, 40, 100, 0.12);
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  height: 143px;
}
.containerMessagePromo {
  height:200px;
  margin-bottom:50px;
  margin-left: 0px;
}
</style>
